<template>
  <div id="laboratoryPressure">
    <el-dialog
      :title="laboratoryPressureFormTitle"
      width="1500px"
      :visible.sync="laboratoryPressureDialogVisible"
      :close-on-click-modal="false"
      @close="laboratoryPressureDialogClose"
    >
      <div id="pdfDom">
        <h1 style="text-align: center">
          实验室压差记录表
        </h1>
        <el-form
          ref="laboratoryPressureFormRef"
          :model="laboratoryPressureForm"
          :rules="laboratoryPressureFormRules"
          label-position="right"
          label-width="100px"
        >
          <el-row>
            <el-col :span="6">
              <el-form-item label="年度" prop="year">
                <el-input v-model="laboratoryPressureForm.year" placeholder="请输入年度" clearable />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="月份" prop="month">
                <el-input v-model="laboratoryPressureForm.month" placeholder="请输入月份" clearable />
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item>
            <vxe-toolbar
              v-if="laboratoryPressureFormTitle === '新增实验室压差记录表'
                || laboratoryPressureFormTitle === '修改实验室压差记录表'"
            >
              <template #buttons>
                <vxe-button @click="insertRowEvent()">
                  新增
                </vxe-button>
              </template>
            </vxe-toolbar>
            <vxe-table
              ref="xTable"
              border
              resizable
              show-overflow
              keep-source
              :data="pressureDetailList"
              :edit-config="{trigger: 'click', mode: 'cell'}"
            >
              <vxe-table-column
                field="operationDate"
                title="日期"
                :edit-render="{name: '$input', props: {type: 'date', clearable: true}}"
              />
              <vxe-table-column
                field="pressureGauge1"
                title="XY-J-073（无菌二更）"
                :edit-render="{name: '$input', props: {clearable: true}}"
              />
              <vxe-table-column
                field="pressureGauge2"
                title="XY-J-074（阳性间二更）"
                :edit-render="{name: '$input', props: {clearable: true}}"
              />
              <vxe-table-column
                field="pressureGauge3"
                title="XY-J-089（阳性间）"
                :edit-render="{name: '$input', props: {clearable: true}}"
              />
              <vxe-table-column
                field="pressureGauge4"
                title="XY-J-090（微生物二更）"
                :edit-render="{name: '$input', props: {clearable: true}}"
              />
              <vxe-table-column field="result" title="监测结果" width="150">
                <template v-slot="{row,column}">
                  <vxe-radio-group v-model="row[column.property]">
                    <vxe-radio :label="1" content="合格" />
                    <vxe-radio :label="2" content="不合格" />
                  </vxe-radio-group>
                </template>
              </vxe-table-column>
              <vxe-table-column
                v-if="laboratoryPressureFormTitle === '新增实验室压差记录表'
                  || laboratoryPressureFormTitle === '修改实验室压差记录表'"
                title="操作"
                width="100"
              >
                <template #default="{ row }">
                  <template>
                    <vxe-button @click="removeRowEvent(row)">
                      删除
                    </vxe-button>
                  </template>
                </template>
              </vxe-table-column>
            </vxe-table>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="laboratoryPressureDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="laboratoryPressureFormSubmit">
          确 定
        </el-button>
        <el-button
          v-if="laboratoryPressureFormTitle === '实验室压差记录表详情'"
          type="primary"
          @click="getPdf"
        >
          下 载 PDF
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="月份">
        <el-input v-model="searchForm.month" placeholder="请输入月份" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="laboratoryPressurePage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
      highlight-current-row
    >
      <el-table-column prop="year" label="年度" />
      <el-table-column prop="month" label="月份" />
      <el-table-column prop="recorder" label="记录人" />
      <!-- <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.recordDate">{{ scope.row.recordDate.substring(0, 10) }}</span>
        </template>
      </el-table-column> -->
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="laboratoryPressurePage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addLaboratoryPressure,
  deleteLaboratoryPressure,
  updateLaboratoryPressure,
  selectLaboratoryPressureInfo,
  selectLaboratoryPressureList
} from '@/api/quality/laboratoryPressure'
import html2PDF from 'jspdf-html2canvas'
import { Loading } from 'element-ui'

export default {
  data () {
    return {
      laboratoryPressureDialogVisible: false,
      laboratoryPressureFormTitle: '',
      laboratoryPressureForm: {
        id: '',
        year: '',
        month: '',
        pressureDetailJson: ''
      },
      laboratoryPressureFormRules: {
        month: [{ required: true, message: '月份不能为空', trigger: ['blur', 'change']}]
      },
      laboratoryPressurePage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        month: ''
      },
      pressureDetailList: []
    }
  },
  created () {
    selectLaboratoryPressureList(this.searchForm).then(res => {
      this.laboratoryPressurePage = res
    })
  },
  methods: {
    laboratoryPressureDialogClose () {
      this.$refs.laboratoryPressureFormRef.resetFields()
      this.pressureDetailList = []
    },
    laboratoryPressureFormSubmit () {
      if (this.laboratoryPressureFormTitle === '实验室压差记录表详情') {
        this.laboratoryPressureDialogVisible = false
        return
      }
      this.$refs.laboratoryPressureFormRef.validate(async valid => {
        if (valid) {
          this.laboratoryPressureForm.pressureDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
          if (this.laboratoryPressureFormTitle === '新增实验室压差记录表') {
            this.laboratoryPressureForm.id = ''
            await addLaboratoryPressure(this.laboratoryPressureForm)
          } else if (this.laboratoryPressureFormTitle === '修改实验室压差记录表') {
            await updateLaboratoryPressure(this.laboratoryPressureForm)
          }
          this.laboratoryPressurePage = await selectLaboratoryPressureList(this.searchForm)
          this.laboratoryPressureDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.laboratoryPressureFormTitle = '新增实验室压差记录表'
      this.laboratoryPressureDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteLaboratoryPressure(row.id)
        if (this.laboratoryPressurePage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.laboratoryPressurePage = await selectLaboratoryPressureList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.laboratoryPressureFormTitle = '修改实验室压差记录表'
      this.laboratoryPressureDialogVisible = true
      this.selectLaboratoryPressureInfoById(row.id)
    },
    handleInfo (index, row) {
      this.laboratoryPressureFormTitle = '实验室压差记录表详情'
      this.laboratoryPressureDialogVisible = true
      this.selectLaboratoryPressureInfoById(row.id)
    },
    selectLaboratoryPressureInfoById (id) {
      selectLaboratoryPressureInfo(id).then(res => {
        this.laboratoryPressureForm.id = res.id
        this.laboratoryPressureForm.year = res.year
        this.laboratoryPressureForm.month = res.month
        this.pressureDetailList = res.pressureDetailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectLaboratoryPressureList(this.searchForm).then(res => {
        this.laboratoryPressurePage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectLaboratoryPressureList(this.searchForm).then(res => {
        this.laboratoryPressurePage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectLaboratoryPressureList(this.searchForm).then(res => {
        this.laboratoryPressurePage = res
      })
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    },
    getPdf () {
      let loading = ''
      let dom = document.getElementById('pdfDom')
      html2PDF(dom, {
        jsPDF: {
          unit: 'px',
          format: 'a4'
        },
        html2canvas: {
          imageTimeout: 15000,
          logging: true,
          useCORS: true
        },
        imageType: 'image/jpeg',
        imageQuality: 1,
        margin: {
          top: 20,
          right: 20,
          bottom: 0,
          left: 0
        },
        output: `实验室压差记录表${this.laboratoryPressureForm.year + '-' + this.laboratoryPressureForm.month}.pdf`,
        init: function () {
          loading = Loading.service({
            lock: true,
            text: 'pdf加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
        },
        success: function (pdf) {
          pdf.save(this.output)
          loading.close()
        }
      })
    }
  }
}
</script>

<style>
</style>

import axios from '@/common/axios'
import qs from 'qs'

export function addLaboratoryPressure (data) {
  return axios({
    method: 'post',
    url: '/quality/laboratoryPressure/add',
    data: qs.stringify(data)
  })
}

export function deleteLaboratoryPressure (id) {
  return axios({
    method: 'delete',
    url: '/quality/laboratoryPressure/delete/' + id
  })
}

export function updateLaboratoryPressure (data) {
  return axios({
    method: 'put',
    url: '/quality/laboratoryPressure/update',
    data: qs.stringify(data)
  })
}

export function selectLaboratoryPressureInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/laboratoryPressure/info/' + id
  })
}

export function selectLaboratoryPressureList (query) {
  return axios({
    method: 'get',
    url: '/quality/laboratoryPressure/list',
    params: query
  })
}
